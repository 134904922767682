export default {
    lastname: [{ required: true}],
    name: [{ required: true}],
    middleName: [{ required: false}],
    gender: [{ required: true}],
    birthdate: [{ required: true}],
    phone: [{ required: true}, { pattern: /^\+\d{1,3}\(\d{3}\)\d{3}-\d{2}-\d{2}$/ }],
    countryId: [{ required: true}],
    documentTypeId: [{ required: true}],
    documentNumber: [{ required: true}],
    carrierId: [{ required: true}],
}