<template>
  <div>
    <b-col cols="12">
      <h2>Водители и пропуска физ. лиц</h2>
    </b-col>
    <b-col cols="12" class="mb-4 d-flex justify-content-end">
      <b-button variant="primary" @click="$refs.createModal.show()">Добавить водителя</b-button>
    </b-col>
    <create ref="createModal"/>
    <update ref="updateModal"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="drivers" :fields="fields" :small="true">
            <template #cell(fio)="data">
              {{ data.item.lastname + ' ' + data.item.name + (data.item.middleName ? ' ' + data.item.middleName : '') }}
            </template>
            <template #cell(actions)="data">
              <div class="text-center">
                <b-icon class="action-icon" icon="pencil-fill" variant="primary" font-scale="1.2" @click="update(data.item)" title="Редактировать"></b-icon>
                <b-icon class="action-icon" icon="trash-fill" variant="danger" font-scale="1.2" @click="remove(data.item.id)" title="Удалить"></b-icon>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex"
import Create from './Drivers/Create'
import Update from './Drivers/Update'

export default {
  name: "Drivers",
  data() {
    return {
      page: 1,
      errors: {},
      fields: [
        {
          key: 'fio',
          label: 'ФИО'
        },
        {
          key: 'carrierName',
          label: 'Перевозчик'
        },
        {
          key: 'phone',
          label: 'Телефон'
        },
        {
          key: 'passNumber',
          label: 'Номер пропуска'
        },
        {
          key: 'job',
          label: 'Должность'
        },
        {
          key: 'actualize',
          label: 'Актуализировал'
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      loading: state => state.Drivers.loading,
      drivers: state => state.Drivers.items,
    })
  },
  mounted() {
    this.$store.dispatch('Drivers/load', { page: 1 })
  },
  methods: {
    remove(id) {
      if (!id)
        return

      if (!confirm('Вы действительно хотите удалить водителя?'))
        return

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Drivers/remove', id)
          .then(() => {
            this.$bvToast.toast('Водитель был успешно удален.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
    update(form) {
      this.$refs.updateModal.show(form)
    }
  },
  components: {
    Create,
    Update
  }
}
</script>

<style scoped>
  .action-icon {
    margin: 4px;
    cursor: pointer;
  }
</style>