<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group label="Фамилия">
          <b-form-input v-model="form.lastname" placeholder="Фамилия" :state="formErrors.lastname"/>
        </b-form-group>
        <b-form-group label="Имя">
          <b-form-input v-model="form.name" placeholder="Имя" :state="formErrors.name"/>
        </b-form-group>
        <b-form-group label="Отчество">
          <b-form-input v-model="form.middleName" placeholder="Отчество"/>
        </b-form-group>
        <b-form-group label="Пол">
          <b-form-select id="gender" v-model="form.gender" :state="formErrors.gender">
            <b-form-select-option :value="null">Выберите пол</b-form-select-option>
            <b-form-select-option :value="1">Мужской</b-form-select-option>
            <b-form-select-option :value="2">Женский</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Дата рождения">
          <b-form-datepicker id="birthdate" :start-weekday="1" v-model="form.birthdate" locale="ru"
                             label-no-date-selected="Дата рождения" :state="formErrors.birthdate"/>
        </b-form-group>
        <b-form-group label="Телефон">
          <b-form-input id="phone" v-model="form.phone" placeholder="+7(999)999-99-99" :state="formErrors.phone"
                        :formatter="phoneFormat"/>
        </b-form-group>
        <b-form-group label="Гражданство">
          <b-form-select id="country" v-model="form.countryId" :state="formErrors.countryId">
            <b-form-select-option :value="null" :key="null">Выберите гражданство</b-form-select-option>
            <b-form-select-option v-for="country in countries" :value="country.id" :key="country.id">
              {{ country.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Номер пропуска">
          <b-form-input v-model="form.passNumber" placeholder="Номер пропуска"/>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Тип документа">
          <b-form-select id="documentType" v-model="form.documentTypeId" :state="formErrors.documentTypeId">
            <b-form-select-option :value="null" :key="null">Выберите тип документа</b-form-select-option>
            <b-form-select-option v-for="documentType in documentTypes" :value="documentType.id"
                                  :key="documentType.id">{{ documentType.document_type }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Номер документа">
          <b-form-input type="number" id="documentNumber" v-model="form.documentNumber" placeholder="Номер документа"
                        :state="formErrors.documentNumber"/>
        </b-form-group>
        <b-form-group label="Должность">
          <b-form-input id="job" v-model="form.job" placeholder="Должность"/>
        </b-form-group>
        <b-form-group label="Перевозчик">
          <b-form-select id="carrier" v-model="form.carrierId" :state="formErrors.carrierId">
            <b-form-select-option :value="null" :key="null">Выберите перевозчика</b-form-select-option>
            <b-form-select-option v-for="carrier in carriers" :value="carrier.id" :key="carrier.id">
              {{ carrier.carrierName }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Фотографии">
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
          <b-form-file class="mb-2" placeholder="Выберите файл" browse-text="Выбрать"/>
        </b-form-group>
        <b-form-group label="Доступы к зонам ТБ">
          <b-form-checkbox v-model="form.transportationSectorOfTheTBZone">
            Допуск в перевозочный сектор зоны ТБ
          </b-form-checkbox>
          <b-form-checkbox v-model="form.technicalSectorOfTheTBZone">
            Допуск в технологический сектор зоны ТБ
          </b-form-checkbox>
          <b-form-checkbox v-model="form.criticalElementsOfOTI">
            Допуск на критические элементы ОТИ
          </b-form-checkbox>
          <b-form-checkbox v-model="form.transportationSectorOfTheTSZone">
            Допуск в перевозочный сектор ТС
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import DRIVER from "@/forms/DRIVER";
import {mapState} from "vuex";
import {phoneFormat} from "@/utils/phone";

const DRIVER_INIT_FORM = () => ({
  lastname: '',
  name: '',
  middleName: '',
  gender: null,
  birthdate: null,
  phone: '',
  countryId: null,
  documentTypeId: null,
  documentNumber: '',
  passNumber: '',
  job: '',
  carrierId: null,
  transportationSectorOfTheTBZone: false,
  technicalSectorOfTheTBZone: false,
  criticalElementsOfOTI: false,
  transportationSectorOfTheTSZone: false,
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => DRIVER_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      countries: state => state.Countries.items,
      carriers: state => state.Carriers.items,
      documentTypes: state => state.DocumentTypes.items
    }),
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
        Object.assign(this.form, form)
    },
    phoneFormat: (value) => phoneFormat(value),
    resetForm() {
      Object.assign(this.form, DRIVER_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, DRIVER)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', this.form);
    }
  }
}
</script>

<style scoped>

</style>